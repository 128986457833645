import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Head from 'components/head';
import { Box } from 'grommet';
import SectionTitle from 'components/title/sectionTitle';
import { BrochureEmail } from '../components/email/email';

const BrochureForm = ({ data }) => (
  <Layout>
    <Head pageTitle={data.formsJson.title} />
    <Box fill="horizontal">
      <Box width="large" alignSelf="center">
        <SectionTitle title="Brochure Download" />
        <Box width="100%" alignSelf="center" height="700px">
          <BrochureEmail></BrochureEmail>
          {/* <iframe
            title="OtoSim Form"
            src="https://www2.otosim.com/l/204492/2019-02-04/8c8r4"
            width="100%"
            height="100%"
            type="text/html"
            frameBorder="0"
            style={{ border: 0 }}
          /> */}
        </Box>
      </Box>
    </Box>
  </Layout>
);

BrochureForm.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BrochureForm;

export const query = graphql`
  query brochureFormQuery {
    formsJson {
      title
    }
  }
`;
