import React, { useEffect, useRef, useState } from 'react';
// import axios from 'axios';
// import emailjs from '@emailjs/browser';
import id from '../../../content/email/email.json';
import './email.css';
import { navigate } from 'gatsby';
// const emailjs = window.emailjs;

export const SupportEmail = () => {
  const [country, setCountry] = useState('N/A');
  const [city, setCity] = useState('N/A');
  const [region, setRegion] = useState('N/A');
  const [ip, setIp] = useState('N/A');
  const [org, setOrg] = useState('N/A');
  const [timeZone, setTz] = useState('N/A');

  const form = useRef();

  useEffect(() => {
    fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(function(data) {
        // console.log(data);

        //this.country.setState(data.country);
        setCountry(data.country_name);
        setCity(data.city);
        setRegion(data.region);
        setIp(data.ip);
        setOrg(data.org);
        setTz(data.timezone);
      });
  });

  const sendEmail = e => {
    e.preventDefault();

    // emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_PUBLIC_KEY')
    emailjs.sendForm(id.s_id, id.ts_id, e.target, id.p_key).then(
      result => {
        console.log(result.text);
        navigate('/support-manuals');
      },
      error => {
        console.log(error.text);
        navigate('/error');
      }
    );
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
      <fieldset id="sentForm">
        <p class="required">
          <label htmlFor="user_first_name">
            <span class="required-icon">★</span>First Name:
          </label>
        </p>
        <p>
          <input
            class="input-text"
            type="text"
            id="user_first_name"
            name="user_first_name"
            required
          />
        </p>
        <p class="required">
          <label htmlFor="user_last_name">
            <span class="required-icon">★</span>Last Name:
          </label>
        </p>
        <p>
          <input
            class="input-text"
            type="text"
            id="user_last_name"
            name="user_last_name"
            required
          />
        </p>
        <p class="required">
          <label htmlFor="user_email">
            <span class="required-icon">★</span>Email:
          </label>
        </p>
        <p>
          <input
            class="input-text"
            type="email"
            id="user_email"
            name="user_email"
            required
          />
        </p>
        <p class="required">
          {/* <label htmlFor="message">Message:</label>
          <textarea id="message" name="message" />
          <br></br> */}
          <label htmlFor="user_company">
            <span class="required-icon">★</span>Company:
          </label>
        </p>
        <p>
          <input
            class="input-text"
            type="text"
            id="user_company"
            name="user_company"
            required
          />
        </p>
        <input type="submit" value="Send" />
        <input
          class="type_from"
          type="hidden"
          id="type_form"
          name="type_form"
          value="Support"
        ></input>
        <input
          class="country"
          type="hidden"
          id="country"
          name="country"
          value={country}
        ></input>
        <input
          class="city"
          type="hidden"
          id="city"
          name="city"
          value={city}
        ></input>
        <input
          class="region"
          type="hidden"
          id="region"
          name="region"
          value={region}
        ></input>
        <input class="ip" type="hidden" id="ip" name="ip" value={ip}></input>
        <input
          class="org"
          type="hidden"
          id="org"
          name="org"
          value={org}
        ></input>
        <input
          class="timeZone"
          type="hidden"
          id="timeZone"
          name="timeZone"
          value={timeZone}
        ></input>
      </fieldset>
    </form>
  );
};

export const BrochureEmail = () => {
  const [country, setCountry] = useState('N/A');
  const [city, setCity] = useState('N/A');
  const [region, setRegion] = useState('N/A');
  const [ip, setIp] = useState('N/A');
  const [org, setOrg] = useState('N/A');
  const [timeZone, setTz] = useState('N/A');

  const form = useRef();

  useEffect(() => {
    fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(function(data) {
        // console.log(data);

        //this.country.setState(data.country);
        setCountry(data.country_name);
        setCity(data.city);
        setRegion(data.region);
        setIp(data.ip);
        setOrg(data.org);
        setTz(data.timezone);
      });
  });

  const sendEmail = e => {
    e.preventDefault();

    // emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_PUBLIC_KEY')
    emailjs.sendForm(id.s_id, id.ts_id, e.target, id.p_key).then(
      result => {
        console.log(result.text);
        navigate('/brochures');
      },
      error => {
        console.log(error.text);
        navigate('/error');
      }
    );
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
      <fieldset id="sentForm">
        <p class="required">
          <label htmlFor="user_first_name">
            <span class="required-icon">★</span>First Name:
          </label>
        </p>
        <p>
          <input
            class="input-text"
            type="text"
            id="user_first_name"
            name="user_first_name"
            required
          />
        </p>
        <p class="required">
          <label htmlFor="user_last_name">
            <span class="required-icon">★</span>Last Name:
          </label>
        </p>
        <p>
          <input
            class="input-text"
            type="text"
            id="user_last_name"
            name="user_last_name"
            required
          />
        </p>
        <p class="required">
          <label htmlFor="user_email">
            <span class="required-icon">★</span>Email:
          </label>
        </p>
        <p>
          <input
            class="input-text"
            type="email"
            id="user_email"
            name="user_email"
            required
          />
        </p>
        <p class="required">
          {/* <label htmlFor="message">Message:</label>
            <textarea id="message" name="message" />
            <br></br> */}
          <label htmlFor="user_company">
            <span class="required-icon">★</span>Company:
          </label>
        </p>
        <p>
          <input
            class="input-text"
            type="text"
            id="user_company"
            name="user_company"
          />
        </p>
        <input type="submit" value="Send" />
        <input
          class="type_from"
          type="hidden"
          id="type_form"
          name="type_form"
          value="Brochure"
        ></input>
        <input
          class="country"
          type="hidden"
          id="country"
          name="country"
          value={country}
        ></input>
        <input
          class="city"
          type="hidden"
          id="city"
          name="city"
          value={city}
        ></input>
        <input
          class="region"
          type="hidden"
          id="region"
          name="region"
          value={region}
        ></input>
        <input class="ip" type="hidden" id="ip" name="ip" value={ip}></input>
        <input
          class="org"
          type="hidden"
          id="org"
          name="org"
          value={org}
        ></input>
        <input
          class="timeZone"
          type="hidden"
          id="timeZone"
          name="timeZone"
          value={timeZone}
        ></input>
      </fieldset>
    </form>
  );
};

export const QuoteEmail = () => {
  const [country, setCountry] = useState('N/A');
  const [city, setCity] = useState('N/A');
  const [region, setRegion] = useState('N/A');
  const [ip, setIp] = useState('N/A');
  const [org, setOrg] = useState('N/A');
  const [timeZone, setTz] = useState('N/A');

  const form = useRef();

  useEffect(() => {
    fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(function(data) {
        // console.log(data);

        //this.country.setState(data.country);
        setCountry(data.country_name);
        setCity(data.city);
        setRegion(data.region);
        setIp(data.ip);
        setOrg(data.org);
        setTz(data.timezone);
      });
  });

  const sendEmail = e => {
    e.preventDefault();

    // emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_PUBLIC_KEY')
    emailjs.sendForm(id.s_id, id.tq_id, e.target, id.p_key).then(
      result => {
        console.log(result.text);
        navigate('/form-success');
      },
      error => {
        console.log(error.text);
        navigate('/error');
      }
    );
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
      <fieldset id="sentForm">
        <p class="required">
          <label htmlFor="user_email">
            <span class="required-icon">★</span>Email:
          </label>
        </p>
        <p>
          <input
            class="input-text"
            type="email"
            id="user_email"
            name="user_email"
            required
          />
        </p>
        <p>
          <label htmlFor="message">Additional Comments:</label>
        </p>
        <p>
          <textarea id="message" name="message" />
          {/* <label htmlFor="message">Message:</label>
              <textarea id="message" name="message" />
              <br></br> */}
        </p>
        <input type="submit" value="Send" />
        <input
          class="country"
          type="hidden"
          id="country"
          name="country"
          value={country}
        ></input>
        <input
          class="city"
          type="hidden"
          id="city"
          name="city"
          value={city}
        ></input>
        <input
          class="region"
          type="hidden"
          id="region"
          name="region"
          value={region}
        ></input>
        <input class="ip" type="hidden" id="ip" name="ip" value={ip}></input>
        <input
          class="org"
          type="hidden"
          id="org"
          name="org"
          value={org}
        ></input>
        <input
          class="timeZone"
          type="hidden"
          id="timeZone"
          name="timeZone"
          value={timeZone}
        ></input>
      </fieldset>
    </form>
  );
};
